:root {
  --mainnav: #1679ab;
  --maincompbg: #f9f9f9;
}

.main-nav {
  width: 100%;
  /* height: 60px; */
  /* background-color: var(--mainnav); */
  padding: 10px;

  /* z-index: 1; */
  color: var(--mainnav);
}
.main-nav-contentbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nav-icon-container {
  /* background-color: white; */
  /* border-radius: 100%; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid grey; */
  cursor: pointer;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  font-size: 30px;
}
.nav-title {
  font-family: "Kiwi Maru";
  font-size: 22px;
  letter-spacing: 10px;
}
.nav-icons-container {
  /* background-color: white; */
  /* border-radius: 100%; */
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 0.5px solid grey; */
  cursor: pointer;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  font-size: 35px;
}
.main-container {
  /* background-color: var(--maincompbg); */
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex-direction: column;
  /* padding: 3%; */
  /* background: url("https://img.freepik.com/free-photo/elegant-blue-flowing-wave-background_1017-32745.jpg?t=st=1718271766~exp=1718275366~hmac=93c5871579d80813b054268db7b9e45c2311f7c4a15d68c7ce5ceb0478d37f40&w=1380"); */
  background: url("../../Images/mainbg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.menu-container {
  position: fixed;
  /* bottom: 50%; */
  /* left: 2.5%;
  transform: translateX(-50%); */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  animation: slideDown 0.5s forwards;
  z-index: 1;
}
@keyframes slideDown {
  from {
    left: -5%;
    top: 10%;
  }
  to {
    top: 10%;
  }
}
.menu-container.slide-up {
  animation: slideUp 0.5s forwards;
}

@keyframes slideUp {
  from {
    top: 10%;
  }
  to {
    left: -5%;
    top: 10%;
  }
}
.menu-item {
  background-color: white;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 0.5px solid grey;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  position: relative;
  cursor: pointer;
  color: var(--mainnav);
  font-size: 32px;
}

.container1 {
  width: 95%;
  height: 100%;
  border-radius: 20px;
  background-color: #cde8e54f;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 0.2px solid grey;
  padding: 10px;
  overflow-y: scroll;
}
.maincomponent {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: var(--maincompbg);
  justify-content: space-between;
  background: url("../../Images/mainbg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.bottom-nav {
  height: 25px;
  display: flex;
  justify-content: center;
  justify-content: center;
}
.crumbs {
  /* background-color: #4ecca3; */
  width: 80%;
}
.bottom-nav-content {
  width: 100%;
  background-color: var(--mainnav);
  /* border-top-left-radius: 50px;
  border-top-right-radius: 50px; */
  padding: 1px;
  text-align: center;
  color: white;
}
.sidebarcanvas {
  /* background-color: white; */
  background: transparent;
  border-right: none;
}
