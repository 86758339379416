body {
  /* font-family: "Noto Serif"; */
  /* font-size: 22px; */
}
:root {
  --mainnav: #344986;
  --maincompbg: #f9f9f9;
}

.licence-main {
  background-color: red;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: aliceblue;
}
.licence-container {
  background-color: white;
  height: 50%;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.licence-logo {
  width: 35%;
}
.font-licence {
  font-family: DM Sans, sans-serif;
  font-size: 20px;
}

/* Login Screen */
/* body {
  margin: 0;
} */
.login-main {
  background-color: white;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://images.pexels.com/photos/743986/pexels-photo-743986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1");
  /* background-repeat: no-repeat; */
  background-attachment: fixed;
  background-size: contain;
}
@keyframes example {
  from {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  }
  to {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
      rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  }
}
.login-container {
  width: 450px;
  height: 70%;
  background-color: white;
  border-radius: 30px;
  border: 0.1px solid rgba(128, 128, 128, 0.453);
  animation: example 3s ease infinite alternate;
  /* padding: 0.5%; */
  display: flex;
  background: rgba(171, 165, 165, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  border: 1px solid rgba(171, 165, 165, 0.17);
}
.login-logo {
  width: 25%;
}
.login-container1 {
  width: 40%;
  background-color: var(--mainnav);
  border-radius: 30px;
  padding: 5px;
  background-image: url("./Images/loginbg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}
.login-inpt-cont {
  /* background-color: aliceblue; */

  width: 70%;
}

/* Standard Creation */

.child-main {
  height: 90%;
  width: 90%;
  background-color: white;
  border-radius: 20px;
  border: 0.5px solid grey;
  padding: 5px;
  overflowy: "auto";
  animation: shadowanimate 3s ease infinite alternate;
  /* From https://css.glass */
  background: rgba(171, 165, 165, 0.17);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.3px);
  -webkit-backdrop-filter: blur(7.3px);
  border: 1px solid rgba(171, 165, 165, 0.17);
}
.child-head {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: var(--mainnav);
}
.childip-main {
  width: 100%;
  /* height: 100%; */
  /* background-color: #344986; */
  display: flex;
  justify-content: center;
}
.child-ip-container {
  background-color: white;
  border: 0.5px solid grey;
  border-radius: 20px;
  width: 60%;
  /* height: 300px; */
  padding: 1%;
}
.childip {
  padding: 5px;
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 95%;
  gap: 10px;
  /* justify-content: center; */
  align-items: center;
}
.grid-item {
  /* background-color: azure;
  border: 1px solid black; */
  text-align: center;
}

.rep-child-main {
  width: 100%;
  height: 100%;
  /* background-color: white; */
  display: flex;
  flex-wrap: wrap;
}
.rep-side-filter {
  width: 15%;
  /* background-color: aqua; */
  /* border-right: 0.5px solid grey; */
  padding: 0.5%;
}
.rep-side-table {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 85%;
  /* background-color: aquamarine; */
  padding: 1%;
}
@media screen and (max-width: 768px) {
  .rep-side-filter,
  .rep-side-table {
    width: 100%;
  }
}
.rep-side-container {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5px;
  animation: shadowanimate 3s ease infinite alternate;
}

.rep--table-container {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  padding: 5px;
  animation: shadowanimate 3s ease infinite alternate;
}

@keyframes shadowanimate {
  from {
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
      rgba(0, 0, 0, 0.22) 0px 15px 12px;
  }
  to {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
      rgba(17, 17, 26, 0.05) 0px 8px 32px;
  }
}
.filter-ip-container {
  padding: 5px;
}

.slide-in {
  position: fixed;

  top: 0;

  left: 0;

  width: 100%;

  height: 100vh;

  background: #0f0f0f;

  transform-origin: bottom;
}

.slide-out {
  position: fixed;

  top: 0;

  left: 0;

  width: 100%;

  height: 100vh;

  /* background: #7a2d2d; */
  background-image: url("https://img.freepik.com/free-photo/elegant-blue-flowing-wave-background_1017-32745.jpg?t=st=1718271766~exp=1718275366~hmac=93c5871579d80813b054268db7b9e45c2311f7c4a15d68c7ce5ceb0478d37f40&w=1380");

  transform-origin: top;
}
.side_anchor {
  text-decoration: none;
}

.staffview_main {
  width: 100%;
  /* background-color: aliceblue; */
  display: flex;
  flex-wrap: wrap;
}
.staffv_container1 {
  width: 30%;
  /* background-color: green; */
  border-right: 0.5px solid rgba(128, 128, 128, 0.427);
  /* border-radius: 10%; */
}
.staffv_container2 {
  width: 70%;
  /* background-color: blue; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
}
.tablecomp {
  /* border-radius: 100px;
  background-color: #0f0f0f; */
}
.showtablestyle {
  padding: 5px;
  height: 400px;
  overflow-y: scroll;
}
@media screen and (max-width: 768px) {
  body {
    font-size: 14px;
  }
  .child-ip-container {
    /* background-color: red; */
    width: 100%;
  }
  .child-main {
    animation: none;
  }
  .childip {
    grid-template-columns: auto auto;
  }
  .rep--table-container {
    overflow: scroll;
    height: 70%;
  }
  .login-main {
    /* background-repeat: no-repeat; */
    background-attachment: fixed;
    background-size: cover;
  }
}
